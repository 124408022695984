import React, { useState, useEffect } from "react"
import { Card, Button, Container } from "react-bootstrap"
import { useHistory, useLocation } from "react-router-dom"
import Header from "./Header"
import Footer from "./Footer"
import Menu from "./Menu"
import { Link } from "@mui/material"

function Order() {
  const location = useLocation()
  const history = useHistory()

  const [orders, setOrders] = useState()
  useEffect(() => {
    try {
      setOrders(location.state.orders)
    } catch {
      history.push("/orderhistory")
    }
  }, [])

  useEffect(() => {}, [orders])

  function handlePrint() {
    if (orders) {
      localStorage.setItem("orders", JSON.stringify(orders))
      let newWindow = window.open(
        "/receipt",
        "Popup",
        "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0"
      )
      newWindow.print()
      window.addEventListener(
        "beforeunload",
        function (e) {
          this.localStorage.removeItem("orders")
        },
        false
      )
    }
  }

  const firstLetterCapital = React.useCallback((string) => {
    if (string) return string.charAt(0).toUpperCase() + string.slice(1)
  }, [])

  if (orders) {
    return (
      <div className="wrapper">
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="p-3">
            <h2>Order Detail</h2>
            <div
              style={{
                backgroundColor: "#f4f6f9",
              }}
            >
              <Container style={{ minHeight: "100vh", marginTop: "10px" }}>
                <div className="w-100">
                  <Card>
                    <Card.Body>
                      <div className="row">
                        <Link
                          onClick={() => history.goBack()}
                          style={{ cursor: "pointer" }}
                        >
                          Back
                        </Link>
                        <h4 className="text-center mb-3">
                          Order # {orders.orderNo}
                        </h4>
                      </div>
                      {orders.status ? (
                        <>
                          {orders.status === "accepted" ? (
                            <h3 className="text-center mb-3 text-success">
                              Status : Accepted
                            </h3>
                          ) : (
                            <>
                              <h3 className="text-center mb-3 text-danger">
                                Status : Declined
                              </h3>
                              <h6 className="text-center mb-3">
                                Reason : {orders.reason}
                              </h6>
                            </>
                          )}{" "}
                        </>
                      ) : (
                        <></>
                      )}

                      <div className="text-center mb-3">
                        <Button
                          variant="success"
                          onClick={handlePrint}
                          style={{ marginBottom: "20px" }}
                        >
                          PRINT
                        </Button>
                        <div className="orderdetails">
                          <div className="leftside-orderdetails">
                            <p>Order Date: </p>
                            <p>Name: </p>
                            <p>House: </p>
                            <p>Address: </p>
                            <p>Town: </p>
                            <p>Postcode: </p>
                            <p>Phone Number: </p>
                            <p>Email: </p>
                            <p>Payment: </p>
                            <p>Subtotal: </p>
                            <p>Discount: </p>
                            <p>Delivery Fee: </p>
                            <p>Total: </p>
                          </div>
                          <div className="rightside-orderdetails">
                            <p>{orders.date}</p>
                            <p>{orders.user}</p>
                            {orders.house ? (
                              <p>{orders.house}</p>
                            ) : (
                              <p>-----</p>
                            )}
                            <p>{orders.address}</p>
                            <p>{orders.town}</p>
                            <p>{orders.postCode}</p>
                            <p>{orders.Telephone}</p>
                            <p>{orders.email}</p>
                            <p>{firstLetterCapital(orders.payment.type)}</p>

                            <p>£{parseFloat(orders.Subtotal).toFixed(2)}</p>
                            <p>- £{parseFloat(orders.Discount).toFixed(2)}</p>
                            <p>£{parseFloat(orders.Fee).toFixed(2)}</p>
                            <p className="text-primary font-weight-bold">
                              £{parseFloat(orders.Total).toFixed(2)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </Container>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
  return null
}

export default Order
