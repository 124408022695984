import React, { useState, useEffect, useRef, useCallback } from "react"
import Header from "./Header"
import Footer from "./Footer"
import Menu from "./Menu"
import {
  Button,
  Alert,
  Container,
  Card,
  Form,
  Modal,
  Row,
  Col,
} from "react-bootstrap"
import { fs, auth } from "../../Config/Config"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormControl from "@mui/material/FormControl"
import FormLabel from "@mui/material/FormLabel"
import { trashO } from "react-icons-kit/fa/trashO"
import { Icon } from "react-icons-kit"
import ServerSideDataTable from "./ServerSideDataTable"
import { useHistory } from "react-router-dom"

function AddCoupon() {
  const history = useHistory()
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        fs.collection("users")
          .doc(user.uid)
          .get()
          .then((snapshot) => {
            if (snapshot.data().isAdmin === false) {
              history.push("/")
            }
          })
      } else {
        history.push("/login")
      }
    })
  }, [])
  const [loadingMsg, setLoadingMsg] = useState("")
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [uidCoupon, setUIDCoupon] = useState("")
  const columns = [
    { field: "id", headerName: "UID", maxWidth: 60, flex: 1 },
    { field: "coupon", headerName: "Voucher", minWidth: 150, flex: 1 },
    { field: "type", headerName: "Discount Type", flex: 1 },
    { field: "value", headerName: "Discount Value", flex: 1 },
    { field: "minimum", headerName: "Minimum Total", flex: 1 },
    {
      field: "remove",
      headerName: "Remove",
      headerAlign: "center",
      align: "center",
      sortable: false,
      width: 80,
      renderCell: (cellValues) => {
        return (
          <div>
            <Button
              variant="danger"
              className="btn-sm"
              onClick={() => {
                setUIDCoupon(cellValues.id)
                handleShow()
              }}
            >
              <Icon icon={trashO} size={20} />
            </Button>
          </div>
        )
      },
    },
  ]
  const [error, setError] = useState("")
  const [message, setMessage] = useState("")
  const couponRef = useRef()
  const [loading, setLoading] = useState(false)
  const [type, setType] = useState(true)
  const fixedRef = useRef()
  const [percent, setPercent] = useState(1)
  const [gName, setgName] = useState("")
  const minimumRef = useRef()
  const [minState, setMinState] = useState(false)

  const [search, setSearch] = useState({
    search: "",
  })
  const handleSearch = (event) => {
    event.preventDefault()
    const newValue = event.target[0].value.toUpperCase()
    if (search.search !== newValue) {
      setSearch({
        search: event.target[0].value.toUpperCase(),
      })
      setPage(0)
    }
  }
  const [triggerReload, setTriggerReload] = useState(false)
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const fetchCoupon = useCallback(async (page, pageSize, search) => {
    let query = fs.collection("coupon").orderBy("coupon", "asc")
    let totalQuery = fs.collection("coupon").orderBy("coupon", "asc")

    if (search.search !== "") {
      query = query
        .where("coupon", ">=", search.search)
        .where("coupon", "<=", search.search + "\uf8ff")
      totalQuery = totalQuery
        .where("coupon", ">=", search.search)
        .where("coupon", "<=", search.search + "\uf8ff")
    }

    if (page > 0) {
      const lastDocSnapshot = await query.limit(page * pageSize).get()
      const lastDoc = lastDocSnapshot.docs[lastDocSnapshot.docs.length - 1]
      query = query.startAfter(lastDoc)
    }

    const snapshot = await query.limit(pageSize).get()
    const totalDocs = await totalQuery.get()
    const data = snapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }))

    return {
      data,
      total: totalDocs.size,
    }
  }, [])

  function handleAddCoupon(e) {
    e.preventDefault()
    try {
      setLoadingMsg("Loading...")
      window.scrollTo(0, 0)
      setMessage("")
      setError("")
      setLoading(true)

      let couponType = ""
      let value = 0

      if (type == true) {
        couponType = "fixed"
        value = Number(fixedRef.current.value)
      } else {
        couponType = "percent"
        value = percent
      }

      let min = ""

      if (minState == false) {
        min = "-"
      } else {
        min = Number(minimumRef.current.value)
      }

      fs.collection("coupon")
        .add({
          coupon: couponRef.current.value.toUpperCase(),
          type: couponType,
          value: value,
          minimum: min,
        })
        .then(() => {
          setMessage("Add Coupon Successful")
          setType(true)
          setPercent(1)
          setMinState(false)
          setgName("")
          setTriggerReload((previousState) => !previousState)
        })
        .catch((error) => {
          setError("Failed to add Coupon")
        })
    } catch (error) {
      setError("Failed to add Coupon")
      console.log("Error catch document: ", error)
    } finally {
      setTimeout(() => {
        setLoading(false)
        setMessage("")
        setLoadingMsg("")
        setError("")
        return
      }, 3000)
    }
  }

  function handleRemoveButton() {
    try {
      setMessage("")
      setError("")
      setLoading(true)
      window.scrollTo(0, 0)
      handleClose()
      fs.collection("coupon")
        .doc(uidCoupon)
        .delete()
        .then(() => {
          setMessage("Remove Coupon Successful")
          setTriggerReload((previousState) => !previousState)
        })
        .catch(() => {
          setError("Failed to remove coupon")
        })
    } catch {
      setError("Failed to remove coupon")
    } finally {
      setTimeout(() => {
        setMessage("")
        setError("")
        setLoadingMsg("")
        setLoading(false)
        return
      }, 3000)
    }
  }

  function handleTypeChange(event) {
    //fixed = true, percent = false
    if (event.target.value == "fixed") {
      setType(true)
    } else if (event.target.value == "percent") {
      setType(false)
    }
  }
  function handlePercentChange(event) {
    let { value, min, max } = event.target
    value = Math.max(Number(min), Math.min(Number(max), Number(value)))
    setPercent(value)
  }
  function generateName() {
    var result = ""
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
    var charactersLength = characters.length
    for (var i = 0; i < 8; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    setgName(result)
  }
  function handleMinimumChange(event) {
    if (event.target.value == "yes") {
      setMinState(true)
    } else if (event.target.value == "no") {
      setMinState(false)
    }
  }
  function handleNameChange(event) {
    event.preventDefault()
    setgName(event.target.value)
  }

  return (
    <div className="wrapper">
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="p-3">
          <h2>Manage Voucher</h2>
          <div>
            <div className="search-container">
              <Form onSubmit={handleSearch} className="mb-2">
                <Row className="gy-2 gx-3">
                  <Col xs={12} sm={6}>
                    <div className="d-flex align-items-center">
                      <Form.Group className="w-100">
                        <Form.Control
                          type="text"
                          placeholder="Voucher Code"
                          size="sm" // Smaller size
                          className="form-control-sm"
                        />
                      </Form.Group>
                      <Button
                        variant="primary"
                        type="submit"
                        className="ml-2 btn-sm "
                      >
                        Search
                      </Button>
                    </div>
                    <span className="text-hint pb-0 mb-0">
                      Matches from start
                    </span>
                  </Col>
                </Row>
              </Form>
            </div>
            <div
              style={{
                backgroundColor: "#FFFF",
              }}
            >
              {loadingMsg ? (
                <Alert variant="secondary">{loadingMsg}</Alert>
              ) : (
                ""
              )}
              {message ? <Alert variant="success">{message}</Alert> : ""}
              {error ? <Alert variant="danger">{error}</Alert> : ""}
              <ServerSideDataTable
                columns={columns}
                fetchData={fetchCoupon}
                search={search}
                setPage={setPage}
                setPageSize={setPageSize}
                page={page}
                pageSize={pageSize}
                triggerReload={triggerReload}
              />
            </div>
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Are you sure?</Modal.Title>
              </Modal.Header>
              <Modal.Body>Do you want to remove this voucher?</Modal.Body>
              <Modal.Footer className="border-0 pt-0 gap-2">
                <Button variant="secondary" onClick={handleClose}>
                  Cancel
                </Button>
                <Button variant="danger" onClick={handleRemoveButton}>
                  Delete
                </Button>
              </Modal.Footer>
            </Modal>
            <Container className="d-flex justify-content-center">
              <div className="w-100 mt-4" style={{ maxWidth: "400px" }}>
                <Card>
                  <Card.Body>
                    <h2 className="text-center mb-4 align-items-center justify-content-center ">
                      Add Voucher
                    </h2>
                    {/* {error && <Alert variant="danger">{error}</Alert>}
                  {message && <Alert variant="success">{message}</Alert>} */}
                    <Form onSubmit={handleAddCoupon}>
                      <Form.Group id="uid" className="mb-3">
                        <FormLabel>Voucher Name</FormLabel>
                        <Form.Control
                          type="text"
                          ref={couponRef}
                          value={gName}
                          required
                          onChange={handleNameChange}
                        />
                        <div className="text-center">
                          <Button className="mt-3" onClick={generateName}>
                            Create Voucher Name
                          </Button>
                        </div>
                        <div className="mt-4">
                          <FormControl>
                            <FormLabel>Discount Type?</FormLabel>
                            <RadioGroup
                              aria-labelledby="demo-controlled-radio-buttons-group"
                              name="controlled-radio-buttons-group"
                              defaultValue="fixed"
                              onChange={handleTypeChange}
                            >
                              <FormControlLabel
                                value="fixed"
                                control={<Radio />}
                                label="Fixed"
                              />
                              <FormControlLabel
                                value="percent"
                                control={<Radio />}
                                label="Percent"
                              />
                            </RadioGroup>
                          </FormControl>
                          {type ? (
                            <div className="mt-2">
                              <FormLabel>Fixed Discount Amount</FormLabel>
                              <Form.Control
                                type="number"
                                ref={fixedRef}
                                required
                              />
                            </div>
                          ) : (
                            <div className="mt-2">
                              <FormLabel>
                                Discount Percentage (Put 1 - 100%)
                              </FormLabel>
                              <Form.Control
                                type="number"
                                value={percent}
                                onChange={handlePercentChange}
                                required
                                min={1}
                                max={100}
                                defaultValue={1}
                              />
                            </div>
                          )}
                        </div>
                        <div className="mt-4">
                          <FormControl>
                            <FormLabel>Minimum Total To Use Voucher?</FormLabel>
                            <RadioGroup
                              aria-labelledby="demo-controlled-radio-buttons-group"
                              name="controlled-radio-buttons-group"
                              defaultValue="no"
                              onChange={handleMinimumChange}
                            >
                              <FormControlLabel
                                value="yes"
                                control={<Radio />}
                                label="Yes"
                              />
                              <FormControlLabel
                                value="no"
                                control={<Radio />}
                                label="No"
                              />
                            </RadioGroup>
                          </FormControl>
                          {minState ? (
                            <div>
                              <FormLabel>Minimum number</FormLabel>
                              <Form.Control
                                type="number"
                                ref={minimumRef}
                                required
                              />
                            </div>
                          ) : null}
                        </div>
                      </Form.Group>
                      <Button
                        disabled={loading}
                        className="w-100 btn btn-success"
                        type="submit"
                      >
                        Create Voucher
                      </Button>
                    </Form>
                  </Card.Body>
                </Card>
              </div>
            </Container>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default AddCoupon
