import React, { useState, useEffect, useCallback } from "react"
import Header from "./Header"
import Footer from "./Footer"
import Menu from "./Menu"
import { useHistory } from "react-router-dom"
import { fs, auth } from "../../Config/Config"
import "react-datepicker/dist/react-datepicker.css"
import ServerSideDataTable from "./ServerSideDataTable"
import moment from "moment"
import { Form, Row, Col, Button } from "react-bootstrap"

function Customer() {
  const history = useHistory()
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        fs.collection("users")
          .doc(user.uid)
          .get()
          .then((snapshot) => {
            if (snapshot.data().isAdmin === false) {
              history.push("/")
            }
          })
      } else {
        history.push("/login")
      }
    })
  }, [])
  const columns = [
    { field: "id", headerName: "UID", flex: 1, maxWidth: 60 },
    {
      field: "date",
      headerName: "Date",
      width: 125,
      flex: 1,
      renderCell: (cellValues) => {
        let formattedDate = ""
        let dateString = cellValues.value
        if (typeof dateString === "string" && dateString.trim() !== "") {
          const datePart =
            dateString.split(" ")[0] +
            " " +
            dateString.split(" ")[1] +
            " " +
            dateString.split(" ")[2]
          const timePart = dateString.split(" ")[3]
          const formattedDatePart = moment(datePart, "MMMM D, YYYY").format(
            "DD/MM/YYYY"
          )
          formattedDate = `${formattedDatePart} ${timePart}`
        } else {
          formattedDate = "-"
        }
        return (
          <div>
            <span>{formattedDate}</span>
          </div>
        )
      },
    },
    { field: "FirstName", headerName: "First Name", flex: 1 },
    { field: "LastName", headerName: "Last Name", flex: 1 },
    { field: "Email", headerName: "E-mail", flex: 1, minWidth: 200 },
    { field: "Telephone", headerName: "Phone", flex: 1, minWidth: 110 },
  ]
  const [loading, setLoading] = useState(false)

  const [search, setSearch] = useState({
    firstName: "",
  })
  const handleSearch = (event) => {
    event.preventDefault()
    const newValue = {
      firstName: event.target[0].value,
    }
    if (search.firstName !== event.target[0].value) {
      setSearch(newValue)
      setPage(0)
    }
  }
  const [triggerReload, setTriggerReload] = useState(false)
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const fetchUser = useCallback(async (page, pageSize, search) => {
    let query = fs.collection("users").orderBy("FirstName", "asc")
    let totalQuery = fs.collection("users").orderBy("FirstName", "asc")

    if (search.firstName !== "") {
      query = query
        .where("FirstName", ">=", search.firstName)
        .where("FirstName", "<=", search.firstName + "\uf8ff")
      totalQuery = totalQuery
        .where("FirstName", ">=", search.firstName)
        .where("FirstName", "<=", search.firstName + "\uf8ff")
    }

    if (page > 0) {
      const lastDocSnapshot = await query.limit(page * pageSize).get()
      const lastDoc = lastDocSnapshot.docs[lastDocSnapshot.docs.length - 1]
      query = query.startAfter(lastDoc)
    }

    const snapshot = await query.limit(pageSize).get()
    const totalDocs = await totalQuery.get()
    const data = snapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }))

    return {
      data,
      total: totalDocs.size,
    }
  }, [])

  return (
    <div className="wrapper">
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="p-3">
          <h2>All Users</h2>
          <div>
            <div className="search-container">
              <Form onSubmit={handleSearch} className="mb-2">
                <Row className="gy-2 gx-3">
                  <Col xs={12} sm={6}>
                    <div className="d-flex align-items-center">
                      <Form.Group className="w-100">
                        <Form.Control
                          type="text"
                          placeholder="First Name"
                          size="sm" // Smaller size
                          className="form-control-sm"
                        />
                      </Form.Group>
                      <Button
                        variant="primary"
                        type="submit"
                        className="ml-2 btn-sm "
                      >
                        Search
                      </Button>
                    </div>
                    <span className="text-hint pb-0 mb-0">
                      Matches from start, case-sensitive
                    </span>
                  </Col>
                </Row>
              </Form>
            </div>
            <div
              style={{
                backgroundColor: "#FFFF",
              }}
            >
              <ServerSideDataTable
                columns={columns}
                fetchData={fetchUser}
                search={search}
                setPage={setPage}
                setPageSize={setPageSize}
                page={page}
                pageSize={pageSize}
                triggerReload={triggerReload}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Customer
