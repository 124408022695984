import React, { useState, useEffect, useCallback } from "react"
import Header from "./Header"
import Footer from "./Footer"
import Menu from "./Menu"
import { useHistory } from "react-router-dom"
import { Button, Alert, Modal, Form, Row, Col } from "react-bootstrap"
import { fs, auth } from "../../Config/Config"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { edit } from "react-icons-kit/fa/edit"
import { trashO } from "react-icons-kit/fa/trashO"
import Icon from "react-icons-kit"
import ServerSideDataTable from "./ServerSideDataTable"

function ManageProducts() {
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        fs.collection("users")
          .doc(user.uid)
          .get()
          .then((snapshot) => {
            if (snapshot.data().isAdmin === false) {
              history.push("/")
            }
          })
      } else {
        history.push("/login")
      }
    })
  }, [])
  const history = useHistory()
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [uidProduct, setUIDProduct] = useState("")

  function GetCategoryFromFirebase() {
    const getCategoryFromFirebase = []
    const [categoryFs, setCategoryFs] = useState()
    useEffect(async () => {
      const snapshot = await fs.collection("category").get()
      snapshot.docs.map((doc) => {
        getCategoryFromFirebase.push({ ...doc.data(), key: doc.id })
      })
      setCategoryFs(getCategoryFromFirebase)
      setLoading(false)
    }, [])
    return categoryFs
  }
  const categoryFs = GetCategoryFromFirebase()

  const columns = [
    { field: "id", headerName: "UID", flex: 1, maxWidth: 60 },
    { field: "title", headerName: "Title", minWidth: 150, flex: 1 },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
    },
    {
      field: "category",
      headerName: "Category",
      renderCell: (cellValues) => {
        const categoryID = cellValues.row.categoryID
        const categoryMatch = categoryFs.find(
          (category) => category.key === categoryID
        )
        const category = categoryMatch ? categoryMatch.category : ""

        return category
      },
      minWidth: 100,
      maxWidth: 180,
      flex: 1,
    },
    {
      field: "price",
      headerName: "Price",
      flex: 1,
      renderCell: (cellValues) => {
        return parseFloat(cellValues.row.price).toFixed(2) //force 2 decimal
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 120,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (cellValues) => {
        return (
          <div className="d-flex justify-content-center gap-3">
            <Button
              variant="success"
              className="btn-sm"
              onClick={() => handleEditButton(cellValues.id)}
            >
              <Icon icon={edit} size={20} />
            </Button>
            <Button
              variant="danger"
              className="btn-sm"
              onClick={() => {
                handleShow()
                setUIDProduct(cellValues.id)
              }}
            >
              <Icon icon={trashO} size={20} />
            </Button>
          </div>
        )
      },
    },
  ]
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState("")
  const [message, setMessage] = useState("")

  function handleEditButton(uid) {
    history.push({
      pathname: "/editproducts",
      state: {
        uid: uid,
      },
    })
  }

  const [search, setSearch] = useState({
    category: "",
  })
  const handleSearch = (event) => {
    event.preventDefault()
    const newSearch = {
      category: event.target[0].value,
    }
    if (newSearch.category !== search.category) {
      setSearch(newSearch)
      setPage(0)
    }
  }
  const [triggerReload, setTriggerReload] = useState(false)
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const fetchProducts = useCallback(async (page, pageSize, search) => {
    let query = fs.collection("Products")
    let totalQuery = fs.collection("Products")

    if (search.category !== "") {
      query = query.where("categoryID", "==", search.category)
      totalQuery = totalQuery.where("categoryID", "==", search.category)
    }

    if (page > 0) {
      const lastDocSnapshot = await query.limit(page * pageSize).get()
      const lastDoc = lastDocSnapshot.docs[lastDocSnapshot.docs.length - 1]
      query = query.startAfter(lastDoc)
    }

    const snapshot = await query.limit(pageSize).get()
    const totalDocs = await totalQuery.get()
    const data = snapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }))

    return {
      data,
      total: totalDocs.size,
    }
  }, [])

  async function handleRemoveButton() {
    try {
      setMessage("")
      setError("")
      setLoading(true)

      handleClose()
      fs.collection("Products")
        .doc(uidProduct)
        .get()
        .then((snapshot) => {
          fs.collection("Products")
            .doc(uidProduct)
            .delete()
            .then(() => {
              setMessage("Remove Products Successful")
              setTriggerReload((previosState) => !previosState)
            })
            .catch(() => {
              setError("Failed to remove products")
            })
        })
    } catch {
      setError("Failed to remove products")
    } finally {
      setTimeout(() => {
        setLoading(false)
        setMessage("")
        setError("")
        return
      }, 3000)
    }
  }

  return (
    <div className="wrapper">
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="p-3">
          <h2>Products</h2>
          <div className="search-container">
            <Form onSubmit={handleSearch} className="mb-2">
              <Row className="gy-2 gx-3">
                <Col xs={12} sm={6}>
                  <div className="d-flex align-items-center">
                    <Form.Group className="w-100">
                      <Form.Select
                        size="sm"
                        className="form-control-sm"
                        aria-label="Select Category"
                      >
                        <option value="">All Category</option>
                        {categoryFs &&
                          categoryFs.length > 0 &&
                          categoryFs.map((category, index) => (
                            <option key={index} value={category.key}>
                              {category.category}
                            </option>
                          ))}
                      </Form.Select>
                    </Form.Group>
                    <Button
                      variant="primary"
                      type="submit"
                      className="ml-2 btn-sm "
                    >
                      Search
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
          <div
            style={{
              backgroundColor: "#FFFF",
            }}
          >
            {message ? <Alert variant="success">{message}</Alert> : ""}
            {error ? <Alert variant="danger">{error}</Alert> : ""}
            <ServerSideDataTable
              columns={columns}
              fetchData={fetchProducts}
              search={search}
              setPage={setPage}
              setPageSize={setPageSize}
              page={page}
              pageSize={pageSize}
              triggerReload={triggerReload}
            />
          </div>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Are you sure?</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                No
              </Button>
              <Button variant="primary" onClick={handleRemoveButton}>
                Yes
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default ManageProducts
