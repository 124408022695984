import React, { useState, useEffect, useRef, useCallback } from "react"
import Header from "./Header"
import Footer from "./Footer"
import Menu from "./Menu"
import { Redirect } from "react-router-dom"
import { Button, Alert, Card, Form, Modal, Row, Col } from "react-bootstrap"
import { fs, auth } from "../../Config/Config"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { edit } from "react-icons-kit/fa/edit"
import { trashO } from "react-icons-kit/fa/trashO"
import Icon from "react-icons-kit"
import ServerSideDataTable from "./ServerSideDataTable"
import { useHistory } from "react-router-dom"

function AddCategory() {
  const history = useHistory()
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        fs.collection("users")
          .doc(user.uid)
          .get()
          .then((snapshot) => {
            if (snapshot.data().isAdmin === false) {
              history.push("/")
            }
          })
      } else {
        history.push("/login")
      }
    })
  }, [])
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = (uid) => {
    setUIDCategory(uid)
    setShow(true)
  }

  const [editShow, setEditShow] = useState(false)
  const handleEditClose = () => {
    setEditShow(false)
    setTitle("")
    setOrder("")
  }
  const handleEditShow = (uid) => {
    setUIDCategory(uid)
    setEditShow(true)
    fs.collection("category")
      .doc(uid)
      .get()
      .then((snapshot) => {
        setUIDCategory(uid)
        setTitle(snapshot.data().category)
        setOrder(snapshot.data().order)
      })
  }

  const [title, setTitle] = useState("")
  const [order, setOrder] = useState("")

  const [uidCategory, setUIDCategory] = useState("")
  const [loadingMsg, setLoadingMsg] = useState("")
  const columns = [
    { field: "id", headerName: "UID", maxWidth: 100, flex: 1 },
    { field: "category", headerName: "Category", flex: 1 },
    { field: "order", headerName: "Sequence", flex: 1, maxWidth: 100 },
    {
      field: "edit",
      headerName: "Edit",
      width: 120,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (cellValues) => {
        return (
          <div className="d-flex gap-3">
            <Button
              variant="success"
              className="btn-sm"
              onClick={() => {
                handleEditShow(cellValues.id)
              }}
            >
              <Icon icon={edit} size={20} />
            </Button>
            <Button
              variant="danger"
              className="btn-sm"
              onClick={() => {
                handleShow(cellValues.id)
              }}
            >
              <Icon icon={trashO} size={20} />
            </Button>
          </div>
        )
      },
    },
  ]
  const [error, setError] = useState("")
  const [message, setMessage] = useState("")
  const categoryRef = useRef()
  const categoryOrderRef = useRef()
  const categoryEditRef = useRef()
  const orderEditRef = useRef()
  const [loading, setLoading] = useState(false)

  const [search, setSearch] = useState({
    search: "",
  })
  const handleSearch = (event) => {
    event.preventDefault()
    const newValue = event.target[0].value
    if (search.search !== newValue) {
      setSearch({
        search: newValue,
      })
      setPage(0)
    }
  }
  const [triggerReload, setTriggerReload] = useState(false)
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const fetchCategory = useCallback(async (page, pageSize, search) => {
    let query = fs.collection("category").orderBy("category", "asc")
    let totalQuery = fs.collection("category").orderBy("category", "asc")

    if (search.search !== "") {
      query = query
        .where("category", ">=", search.search)
        .where("category", "<=", search.search + "\uf8ff")
      totalQuery = totalQuery
        .where("category", ">=", search.search)
        .where("category", "<=", search.search + "\uf8ff")
    }

    if (page > 0) {
      const lastDocSnapshot = await query.limit(page * pageSize).get()
      const lastDoc = lastDocSnapshot.docs[lastDocSnapshot.docs.length - 1]
      query = query.startAfter(lastDoc)
    }

    const snapshot = await query.limit(pageSize).get()
    const totalDocs = await totalQuery.get()
    const data = snapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }))

    return {
      data,
      total: totalDocs.size,
    }
  }, [])

  function handleAddCategory(e) {
    e.preventDefault()
    try {
      setMessage("")
      setError("")
      window.scrollTo(0, 0)
      handleClose()
      setLoading(true)
      fs.collection("category")
        .add({
          category: categoryRef.current.value,
          order: categoryOrderRef.current.value,
        })
        .then(() => {
          setMessage("Add Category Successful")
          setTriggerReload((previosState) => !previosState)
        })
        .catch((error) => {
          setError("Failed to add Category")
        })
    } catch (error) {
      setError("Failed to add Category")
    } finally {
      setTimeout(() => {
        setLoading(false)
        setMessage("")
        setLoadingMsg("")
        setError("")
        return
      }, 3000)
    }
  }

  function handleRemoveButton() {
    try {
      setMessage("")
      setError("")
      setLoading(true)
      handleClose()
      window.scrollTo(0, 0)
      fs.collection("category")
        .doc(uidCategory)
        .delete()
        .then(() => {
          setMessage("Remove Category Successful")
          setTriggerReload((previosState) => !previosState)
        })
        .catch(() => {
          setError("Failed to remove Category")
        })
    } catch {
      setError("Failed to remove Category")
    } finally {
      setTimeout(() => {
        setLoading(false)
        setLoadingMsg("")
        setMessage("")
        setError("")
        return
      }, 3000)
    }
  }
  function handleEditSave(e) {
    e.preventDefault()
    try {
      setMessage("")
      setError("")
      setLoading(true)
      handleEditClose()
      window.scrollTo(0, 0)
      fs.collection("category")
        .doc(uidCategory)
        .update({
          category: categoryEditRef.current.value,
          order: orderEditRef.current.value,
        })
        .then(() => {
          setMessage("Edit Category Successful")
          setTriggerReload((previosState) => !previosState)
        })
        .catch(() => {
          setError("Failed to edit Category")
        })
    } catch {
      setError("Failed to edit Category")
    } finally {
      setTimeout(() => {
        setLoading(false)
        setLoadingMsg("")
        setMessage("")
        setError("")
        return
      }, 3000)
    }
  }
  return (
    <div className="wrapper">
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="p-3">
          <h2>Category</h2>
          <div>
            <div className="search-container">
              <Form onSubmit={handleSearch} className="mb-2">
                <Row className="gy-2 gx-3">
                  <Col xs={12} sm={6}>
                    <div className="d-flex align-items-center">
                      <Form.Group className="w-100">
                        <Form.Control
                          type="text"
                          placeholder="Category Name"
                          size="sm" // Smaller size
                          className="form-control-sm"
                        />
                      </Form.Group>
                      <Button
                        variant="primary"
                        type="submit"
                        className="ml-2 btn-sm "
                      >
                        Search
                      </Button>
                    </div>
                    <span className="text-hint pb-0 mb-0">
                      Matches from start, case-sensitive
                    </span>
                  </Col>
                </Row>
              </Form>
            </div>
            <div
              style={{
                backgroundColor: "#FFFF",
              }}
            >
              {loadingMsg ? (
                <Alert variant="secondary">{loadingMsg}</Alert>
              ) : (
                ""
              )}
              {message ? <Alert variant="success">{message}</Alert> : ""}
              {error ? <Alert variant="danger">{error}</Alert> : ""}
              <ServerSideDataTable
                columns={columns}
                fetchData={fetchCategory}
                search={search}
                setPage={setPage}
                setPageSize={setPageSize}
                page={page}
                pageSize={pageSize}
                triggerReload={triggerReload}
              />
            </div>
            <Modal show={show} onHide={handleClose}>
              <Modal.Header>
                <Modal.Title>
                  <>Do you sure you want to remove this category?</>
                  <p className="mt-4" style={{ color: "red" }}>
                    CAUTION!
                  </p>
                  <p style={{ fontSize: "18px", color: "red" }}>
                    Products that are in this category will become invisible
                    when clicked the home page category tab. Because the
                    category was already deleted.
                  </p>
                  <p style={{ fontSize: "18px", color: "red" }}>
                    If you still want to delete the category that have product
                    assign to it, Please select new category for that product in
                    Edit Product (Manage Products Tab).
                  </p>
                  <p style={{ fontSize: "18px", color: "blue" }}>
                    If you only want to change the name or order and have the
                    product(s) to keep the same category, Please use Edit
                    button.
                  </p>
                </Modal.Title>
              </Modal.Header>
              <Modal.Footer>
                <Button
                  variant="primary"
                  onClick={handleClose}
                  className="mr-3"
                >
                  Cancel
                </Button>
                <Button variant="danger" onClick={handleRemoveButton}>
                  Delete
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal show={editShow} onHide={handleEditClose}>
              <Modal.Header closeButton>
                <Modal.Title>Edit Category</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form onSubmit={handleEditSave}>
                  <Form.Group id="uid" className="mb-3">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      ref={categoryEditRef}
                      defaultValue={title}
                      required
                    />
                    <Form.Label>Order</Form.Label>
                    <Form.Control
                      type="number"
                      ref={orderEditRef}
                      defaultValue={order}
                      required
                    />
                  </Form.Group>
                  <div className="w-100 d-flex justify-content-center ">
                    <Button
                      disabled={loading}
                      className="mt-1 mb-2"
                      type="submit"
                    >
                      Save
                    </Button>
                  </div>
                </Form>
              </Modal.Body>
            </Modal>

            <div
              className="w-100 mt-4"
              style={{
                maxWidth: "400px",
                margin: "auto",
              }}
            >
              <Card>
                <Card.Body>
                  <h3 className="text-center mb-2 align-items-center justify-content-center ">
                    Add Category
                  </h3>
                  <Form onSubmit={handleAddCategory}>
                    <Form.Group id="uid" className="mb-3">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        ref={categoryRef}
                        required
                        className="mb-2"
                      />
                      <Form.Label>Order</Form.Label>
                      <Form.Control
                        type="number"
                        ref={categoryOrderRef}
                        required
                        className="mb-2"
                      />
                    </Form.Group>
                    <Button disabled={loading} className="w-100" type="submit">
                      Add
                    </Button>
                  </Form>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default AddCategory
