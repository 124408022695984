import React, { useEffect } from "react"
import { Route, Redirect } from "react-router-dom"
import Content from "./Content"
import Header from "./Header"
import Menu from "./Menu"
import Footer from "./Footer"
import { auth, fs } from "../../Config/Config"
import { useHistory } from "react-router-dom"

function Dashboard() {
  const history = useHistory()
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        fs.collection("users")
          .doc(user.uid)
          .get()
          .then((snapshot) => {
            if (snapshot.data().isAdmin === false) {
              history.push("/")
            }
          })
      } else {
        history.push("/login")
      }
    })
  }, [])

  return (
    <Route
      render={(props) => {
        return (
          <div className="wrapper">
            <Header />
            <Menu />
            <Content />
            <Footer />
          </div>
        )
      }}
    />
  )
}

export default Dashboard
