import React from "react"
import "./Receipt.css"
import "../../font/Kanit-Regular.ttf"

function Receipt() {
  const orders = JSON.parse(localStorage.getItem("orders"))

  window.addEventListener(
    "beforeunload",
    function (e) {
      this.localStorage.removeItem("orders")
    },
    false
  )

  // false (for now)
  if (orders) {
    // console.log(orders)
    return (
      <>
        <div className="receipt">
          <div className="ticket">
            <div className="centered">
              Thai Sun Restaurants
              {orders.payment.type === "cash" ? (
                <>
                  {orders.pickupState ? (
                    <p style={{ fontSize: "24px" }}>CASH COLLECTION</p>
                  ) : (
                    <p style={{ fontSize: "24px" }}>CASH DELIVERY</p>
                  )}
                </>
              ) : (
                <>
                  {orders.pickupState ? (
                    <p style={{ fontSize: "24px" }}>PAYPAL COLLECTION</p>
                  ) : (
                    <p style={{ fontSize: "24px" }}>PAYPAL DELIVERY</p>
                  )}
                </>
              )}
              {orders.date}
            </div>
            <table className="receipt-table">
              <thead>
                <tr>
                  <th className="quantity">Q.</th>
                  <th className="description">Description</th>
                  <th className="price">£</th>
                </tr>
              </thead>
              <tbody>
                {orders.cartProducts.map((cart, index) => (
                  <tr key={index}>
                    <td className="quantity">{cart.qty} x</td>
                    <td className="description">
                      {cart.title}
                      {cart.option.map((option) => (
                        <p style={{ paddingLeft: "1.1em", margin: "0" }}>
                          {option.menu}
                        </p>
                      ))}
                      {cart.addOn.map((addon) => (
                        <p style={{ paddingLeft: "1.1em", margin: "0" }}>
                          {addon.menu}
                        </p>
                      ))}
                      <p>{cart.instruction}</p>
                    </td>
                    <td className="price">
                      {parseFloat(cart.TotalProductPrice).toFixed(2)}
                    </td>
                  </tr>
                ))}
                <tr>
                  <td className="quantity" />
                  <td
                    className="description"
                    style={{ tabSize: "4", fontWeight: "bold" }}
                  >
                    SUBTOTAL
                  </td>
                  <td className="price" style={{ fontWeight: "bold" }}>
                    £{parseFloat(orders.Subtotal).toFixed(2)}
                  </td>
                </tr>
                {orders.Coupon ? (
                  <>
                    <tr>
                      <td className="quantity" />
                      <td className="description" style={{ tabSize: "4" }}>
                        Discount
                      </td>
                      <td className="price">
                        -£{parseFloat(orders.Discount).toFixed(2)}
                      </td>
                    </tr>
                  </>
                ) : (
                  <></>
                )}

                {orders.deliveryState ? (
                  <tr>
                    <td className="quantity" />
                    <td className="description" style={{ tabSize: "4" }}>
                      Delivery
                    </td>
                    <td className="price">
                      £{parseFloat(orders.Fee).toFixed(2)}
                    </td>
                  </tr>
                ) : (
                  <></>
                )}

                <tr>
                  <td className="quantity" />
                  <td
                    className="description"
                    style={{ tabSize: "4", fontWeight: "bold" }}
                  >
                    TOTAL
                  </td>
                  <td className="price" style={{ fontWeight: "bold" }}>
                    £{parseFloat(orders.Total).toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <p style={{ paddingLeft: "0.8em", fontSize: "22px" }}>
              {orders.instructionToRes ? (
                <p style={{ fontSize: "18px" }}>
                  Instructions: {orders.instructionToRes}
                </p>
              ) : null}
              {orders.user}
              <br />
              {orders.house +
                " " +
                orders.address +
                " " +
                orders.town +
                " " +
                orders.postCode}
              <br />
              PHONE: {orders.Telephone}
              <br />
            </p>
            <p className="centered" style={{ paddingLeft: "0.2em" }}>
              Thanks for your purchase!
              <br />
            </p>
          </div>
        </div>
      </>
    )
  }

  // if (orders) {
  //   return printer
  // }

  return <>null</>
}

export default Receipt
